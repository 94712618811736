import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql, Link } from "gatsby"

const NavItem = styled(Link)`
  text-decoration: none;
  color: #eff1ef;
  font-size: 16px;
  font-weight: 400;
  display: inline-block;
  white-space: nowrap;
  margin: 0 1vw;
  transition: all 200ms ease-in;
  position: relative;
  :after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 0%;
    content: ".";
    color: transparent;
    background: #edd9af;
    height: 1px;
    transition: all 0.4s ease-in;
  }
  :hover {
    color: #edd9af;
    ::after {
      width: 100%;
    }
  }
  @media (max-width: 768px) {
    padding: 20px 0;
    font-size: 1.5rem;
    z-index: 6;
  }
`

export const NavBtnLink = styled(Link)`
  border-radius: 4px;
  background: #c6af6d;
  padding: 10px 22px;
  color: #eff1ef;
  font-size: 16px;
  letter-spacing: 0.7px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  /* Second Nav */
  &:hover {
    transition: all 0.2s ease-in-out;
    background: #937B39;
    color: #eff1ef;
  }
  @media (min-width: 769px) {
    margin-left: 24px;
  }
`

const NavbarLinks = () => {
  const data = useStaticQuery(graphql`
    {
      allWpMenu(filter: { databaseId: { eq: 17 } }) {
        nodes {
          menuItems {
            nodes {
              path
              label
              id
            }
          }
        }
      }
    }
  `)

  const { allWpMenu } = data

  return (
    <>
      {allWpMenu.nodes[0].menuItems.nodes.map(({ path, label, id }) => (
        <NavItem key={id} to={`/en${path}`}>
          {label}
        </NavItem>
      ))}
      <NavBtnLink to="/en/contact/">contact</NavBtnLink>
    </>
  )
}

export default NavbarLinks