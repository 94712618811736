import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import parse from "html-react-parser"
import styled from "styled-components"
import BluePageContent from "../StyledElements/bluePageContent"

const SplitBlueEN = () => {
  const data = useStaticQuery(graphql`
    query textsectionEN {
      allWpEngelsk(filter: { databaseId: { eq: 1307 } }) {
        nodes {
          ACFforside {
            blueSectionKnap {
              knapTekst
              knapUrl
            }
            blueText {
              heading
              textElementLeft
              textElementRight
            }
          }
        }
      }
    }
  `)

  return (
    <BluePageContent>
      <h2>{data.allWpEngelsk.nodes[0].ACFforside.blueText.heading}</h2>
      <TwoGrid>
        <p>
          {parse(
            `${data.allWpEngelsk.nodes[0].ACFforside.blueText.textElementLeft}`
          )}
        </p>
        <p>
          {parse(
            `${data.allWpEngelsk.nodes[0].ACFforside.blueText.textElementRight}`
          )}
        </p>
      </TwoGrid>
      <BtnLink
        to={data.allWpEngelsk.nodes[0].ACFforside.blueSectionKnap.knapUrl}
      >
        {data.allWpEngelsk.nodes[0].ACFforside.blueSectionKnap.knapTekst}
      </BtnLink>
    </BluePageContent>
  )
}

export default SplitBlueEN

const TwoGrid = styled.div`
  @media (min-width: 993px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 4rem;
    grid-auto-rows: 1fr;
    position: relative;
  }

  & p {
    padding-left: 0;
    font-size: 16px;
    font-weight: 300;
  }
`

export const BtnLink = styled(Link)`
  border-radius: 4px;
  background: #c6af6d;
  padding: 10px 22px;
  color: #eff1ef;
  font-size: 18px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  float: right;
  /* Second Nav */
  margin-left: 24px;
  &:hover {
    transition: all 0.2s ease-in-out;
    background: #c6af6d;
    color: #eff1ef;
  }
`
