import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import WhitePageContent from "../StyledElements/whitePageContent"

const LogogridEN = () => {
  const data = useStaticQuery(graphql`
    query LogoQueryEN {
      allWpEngelsk(filter: { databaseId: { eq: 1307 } }) {
        nodes {
          ACFforside {
            logogridOverskrift
            logoGrid {
              img1 {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      width: 104
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
              }
              img8 {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      width: 104
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
              }
              img7 {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      width: 104
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
              }
              img6 {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      width: 104
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
              }
              img5 {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      width: 104
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
              }
              img4 {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      width: 104
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
              }
              img3 {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      width: 104
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
              }
              img2 {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      width: 104
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  return (
    <WhitePageContent>
      <h2>{data.allWpEngelsk.nodes[0].ACFforside.logogridOverskrift}</h2>
      <GridWrap>
        <LogoWrap>
          {" "}
          <StyledLogo
            image={
              data.allWpEngelsk.nodes[0].ACFforside.logoGrid.img1.localFile
                .childImageSharp.gatsbyImageData
            }
            alt=""
          />
        </LogoWrap>
        <LogoWrap>
          {" "}
          <StyledLogo
            image={
              data.allWpEngelsk.nodes[0].ACFforside.logoGrid.img2.localFile
                .childImageSharp.gatsbyImageData
            }
            alt=""
          />
        </LogoWrap>
        <LogoWrap>
          {" "}
          <StyledLogo
            image={
              data.allWpEngelsk.nodes[0].ACFforside.logoGrid.img3.localFile
                .childImageSharp.gatsbyImageData
            }
            alt=""
          />
        </LogoWrap>
        <LogoWrap>
          {" "}
          <StyledLogo
            image={
              data.allWpEngelsk.nodes[0].ACFforside.logoGrid.img4.localFile
                .childImageSharp.gatsbyImageData
            }
            alt=""
          />
        </LogoWrap>
        <LogoWrap>
          {" "}
          <StyledLogo
            image={
              data.allWpEngelsk.nodes[0].ACFforside.logoGrid.img5.localFile
                .childImageSharp.gatsbyImageData
            }
            alt=""
          />
        </LogoWrap>
        <LogoWrap>
          {" "}
          <StyledLogo
            image={
              data.allWpEngelsk.nodes[0].ACFforside.logoGrid.img6.localFile
                .childImageSharp.gatsbyImageData
            }
            alt=""
          />
        </LogoWrap>
        <LogoWrap>
          {" "}
          <StyledLogo
            image={
              data.allWpEngelsk.nodes[0].ACFforside.logoGrid.img7.localFile
                .childImageSharp.gatsbyImageData
            }
            alt=""
          />
        </LogoWrap>
        <LogoWrap>
          {" "}
          <StyledLogo
            image={
              data.allWpEngelsk.nodes[0].ACFforside.logoGrid.img8.localFile
                .childImageSharp.gatsbyImageData
            }
            alt=""
          />
        </LogoWrap>
      </GridWrap>
    </WhitePageContent>
  )
}

export default LogogridEN

const GridWrap = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 4fr));
  grid-gap: 25px;
`
const LogoWrap = styled.div`
  background-color: #ffffff;
  height: 200px;
  text-align: center;
`

const StyledLogo = styled(GatsbyImage)`
  max-height: 200px;
  max-width: 200px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
`
