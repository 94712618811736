import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import WhitePageContent from "../StyledElements/whitePageContent"
import parse from "html-react-parser"

const BottomsectionEN = () => {
  const data = useStaticQuery(graphql`
    query whitesectionEN {
      allWpEngelsk(filter: { databaseId: { eq: 1307 } }) {
        nodes {
          ACFforside {
            bundSektionHvid
          }
        }
      }
    }
  `)

  return (
    <WhitePageContent>
      <div>
        {parse(`${data.allWpEngelsk.nodes[0].ACFforside.bundSektionHvid}`)}
      </div>
    </WhitePageContent>
  )
}

export default BottomsectionEN
