import React, { useState, useEffect } from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { SEOContext } from "gatsby-plugin-wpgraphql-seo"
import PropTypes from "prop-types"
import styled from "styled-components"
import Navbar from "./Navbar/NavbarEN"
import Footer from "./Footer/FooterEN"
import GlobalStyle from "./StyledElements/globalStyles"
import Wrapper from "./StyledElements/wrapper"
import NoticePopup from "./Notice/NoticePopup"

const Layout = ({ children }) => {
  const {
    wp: { seo },
  } = useStaticQuery(graphql`
  query SiteInfoQueryEN {
    wp {
      seo {
        contentTypes {
          post {
            title
            schemaType
            metaRobotsNoindex
            metaDesc
          }
          page {
            metaDesc
            metaRobotsNoindex
            schemaType
            title
          }
        }
        webmaster {
          googleVerify
          yandexVerify
          msVerify
          baiduVerify
        }
        schema {
          companyName
          personName
          companyOrPerson
          wordpressSiteName
          siteUrl
          siteName
          inLanguage
          logo {
            sourceUrl
            mediaItemUrl
            altText
          }
        }
        social {
          facebook {
            url
            defaultImage {
              sourceUrl
              mediaItemUrl
            }
          }
          instagram {
            url
          }
          linkedIn {
            url
          }
          mySpace {
            url
          }
          pinterest {
            url
            metaTag
          }
          twitter {
            username
          }
          wikipedia {
            url
          }
          youTube {
            url
          }
        }
      }
    }
  }
`)

  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    const hasVisited = localStorage.getItem('hasVisited');

    if (!hasVisited) {
      setModalOpen(true);
    }
  }, []);

  const handleCloseModal = () => {
    setModalOpen(false);
    localStorage.setItem('hasVisited', 'true');
  };

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: "en",
        }}
      >
        {/* Add alternate link tags */}
        <link rel="alternate" href="https://marketingfabrikken.dk/en/" hreflang="en" />
        <link rel="alternate" href="https://marketingfabrikken.dk/" hreflang="da-DK" />
        <link rel="alternate" href="https://marketingfabrikken.dk/en/" hreflang="x-default" />
      </Helmet>
      <SEOContext.Provider value={{ global: seo }}>
        <GlobalStyle />
        <Navbar />
        <Main>
          <Wrapper>{children}</Wrapper>
        </Main>
        <Footer />
        <NoticePopup isOpen={modalOpen} onClose={handleCloseModal} />
      </SEOContext.Provider>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

const Main = styled.main`
  min-height: 100vh;
  background: linear-gradient(#edd9af, #455260) top / 100% 100vh;
  background-repeat: no-repeat;
  padding-top: 12vh;
  padding-bottom: 2vh;
  box-shadow: 0px 20px 150px #d6dee4;
  background-attachment: fixed;

  @media (max-width: 991px) {
    padding-top: 0vh;
  }
`
